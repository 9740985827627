import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const LearnDropdown = () => {
  const [isLearnHovered, setIsLearnHovered] = useState(false);

  return (
    <div
      className={`nav-dropdown-container ${isLearnHovered ? 'modalSelected' : ''}`}
      onMouseEnter={() => setIsLearnHovered(true)}
      onMouseLeave={() => setIsLearnHovered(false)}
    >
      Learn
      <svg
        className={`nav-dropdown-arrow ${isLearnHovered ? 'rotate' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>
      {isLearnHovered && (
        <div className="nav-dropdown-content">
          <Link href="/learn" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/school.png"
                    alt="Crypto Lessons Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">Crypto Lessons</div>
                  <div className="dropdown-subtitle">
                    Master the basics of cryptocurrency and blockchain technology.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>

          <Link href="/quizzes" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/reading.png"
                    alt="Crypto Quizzes Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">Crypto Quizzes</div>
                  <div className="dropdown-subtitle">
                    Test your crypto knowledge and earn rewards!
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default LearnDropdown;
