import React, { useState, useEffect } from 'react';
import LaptopNavbar from './LaptopNavbar';
import MobileNavbar from './MobileNavbar';
import MobileMenu from './MobileMenu';

const Navbar: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    handleResize(); 

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <nav>
      {isMobile ? (
        <>
          <MobileNavbar toggleModal={toggleModal} /> 
          <MobileMenu isModalOpen={isModalOpen} toggleModal={toggleModal} setIsModalOpen={setIsModalOpen}/>
        </>
      ) : (
        <LaptopNavbar />
      )}
    </nav>
  );
};

export default Navbar;