
import React, { useState } from 'react';
import Link from 'next/link';
import ConnectWalletButton from '../Account/ConnectWalletButton';
import { usePinxPrice } from '../../context/PinxPriceContext';
import SupportDropdown from './SupportDropdown';
import TokenInfoDropdown from './TokenInfoDropdown';
import NewsDropdown from './NewsDropdown';
import CompaniesDropdown from './CompaniesDropdown';
import LearnDropdown from './LearnDropdown';
import BuyPinxDropdown from './BuyPinxDropdown';

const LaptopNavbar: React.FC = () => {
  const { price: pinxPrice } = usePinxPrice();

  return (
    <div className="nav-holder">
      <div className="logo-nav-container">
        <div className="logo-text">
          <Link href="/" passHref>
            <a className="logo-text">PINX Global</a>
          </Link>
        </div>
        <div className="nav-separator"></div> 
        <div className="nav-links">
                          <BuyPinxDropdown/>
                          <LearnDropdown/>
                          <CompaniesDropdown/>
                          <NewsDropdown/>
                          <TokenInfoDropdown/>
                          <SupportDropdown/>
                        </div>
                    </div>


      <div className="navbar-user">
        <div className="user-options">
          <div className="user-inside-options">
            <Link href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" passHref>
            <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                <div className="price-holder-button">
                  <span className="pinx-text">$PINX</span> <span className="price-text">${pinxPrice}</span>
                </div>
              </a>
            </Link>
            <div className="navbar-separator"></div>
            <ConnectWalletButton />
          </div>
        </div>

      </div>




      <style jsx global>{`
      .nav-dropdown-container.modalSelected .nav-dropdown-content {
          max-height: 500px; 
          opacity: 1; 
      }

         .nav-dropdown-container {
          display: flex;
          align-items: center;
          position: relative;
          width: auto;
          padding: 0 10px;
          font-size: 21px;
          cursor: pointer;
          font-weight: 500;
          white-space: nowrap;
          transition: all 0.3s ease 0s;
          text-decoration: none !important;
          color: white;
          height: 100%;
          }


        .nav-dropdown-arrow {
          width: 15px;
          height: auto; 
          fill: currentColor; 
          margin-left: 5px; 
          vertical-align: middle; 
          transition: transform 0.3s ease;
        }
        
        .nav-dropdown-arrow.rotate {
          transform: rotate(180deg); /* Rotate arrow */
        }

            .nav-dropdown-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%; /* Directly below the navbar */
            left: 0;
            color: white;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            width: auto;
            min-width: 160px;
            max-height: 0; /* Start with max-height 0 */
            overflow: hidden;
            transition: max-height 0.5s ease-out, opacity 0.5s ease;
            opacity: 0; /* Start with opacity 0 */
            }

            .nav-body-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 80px;
            transition: background 0.3s ease, box-shadow 0.3s ease; 
            
            /* Enhanced 3D Gradient from top to bottom */
            background: linear-gradient(to bottom, rgba(140, 20, 70, 1) 0%, rgba(80, 0, 40, 1) 100%);
            
            border-radius: 10px; /* Rounded corners */
            margin: 6px 10px;

            /* Stronger outer shadow for raised effect */
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3), inset 0 3px 6px rgba(255, 255, 255, 0.2);
            }

            .nav-body-item:hover {
            /* Darker gradient on hover to mimic press effect */
            background: linear-gradient(to bottom, rgba(100, 0, 50, 1) 0%, rgba(60, 0, 30, 1) 100%);
            
            /* Pressed down effect with deeper shadow */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), inset 0 -2px 6px rgba(255, 255, 255, 0.1);
            }

            .dropdown-content {
              flex: 1 1;
            }

            .dropdown-icon {   
                width: 40px;
                padding-left: 10px;
                box-sizing: content-box;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                margin: 0px 10px;
            }

            .dropdown-svg {   
              width: 40px;
              padding-left: 10px;
              box-sizing: content-box;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              fill: white;
              vertical-align: middle;
              transition: transform 0.3s ease; 
          }

            .dropdown-title {
              font-size: 20px;
              line-height: 20px;
              color: var(--hbg-header-main-txtcolor);
              margin-bottom: 4px;
            }

            .dropdown-subtitle {
              font-size: 14px;
              line-height: 16px;
              color: var(--hbg-header-main-txtcolor);
              opacity: .5;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
          }




          a, a:active, a:focus, a:hover {
            outline: none;
            text-decoration: none;
            color: white;
            transition: transform 0.3s ease;
          }
          a:hover {
            transform: scale(1);
          }



        .nav-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 12px;
        background: linear-gradient(to bottom, rgba(80, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); 
        color: #fff;
        position: relative;
        z-index: 1000;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); 
        }
        .logo-nav-container {
          display: flex;
          align-items: center;
          height: 60px;
        position: relative;
        }
        .logo-text {
          font-weight: bold;
          font-size: 25px;
          color: #fff;
          margin: 0;
          white-space:nowrap;
        }
        .nav-links {
          display: flex;
          height: 100%;
          align-items: center;
          flex-direction: row;
        }



        .nav-separator {
          border: none;
          flex-shrink: 0;
          background: #D3D3D3;
          width: 1px;
          margin: 0 20px;
          height: 16px;
        }


        

                .navbar-separator {
        border: none;
        flex-shrink: 0;
        background: #D3D3D3;
        display: block;
        width: 1px;
        margin: 0px 20px 0px 18px;
        height: 16px !important;
        }


        .navbar-user {
        display: flex;
        align-items: center;
        }

        .user-options {
        display: flex;
        align-items: center;
        flex-direction: row;
        }

        .user-inside-options {
        display: flex;
        align-items: center;
        margin-right: 0px;
        flex-direction: row;
        width: auto;
        padding: 0px;
        justify-content: unset;
        }

        .price-holder-button {
        width: unset;
        padding: 9px 12px;
        font-weight: 500;
        font-size: 19px;
        line-height: 130%;
        text-align: center;
        color: white;
        margin: 0px;
        transition: all 0.3s ease 0s;
        border: unset;
        border-radius: 24px;
        cursor: pointer;
        }

        .pinx-text {
        color: #ff69b4;
        text-shadow: 0 0 8px rgba(255, 105, 180, 0.7);
        }

        .price-text {
        color: #00ff00;
        text-shadow: 0 0 8px rgba(0, 255, 0, 0.7);
        }

         @media (max-width: 1500px) {
          .logo-text {
            font-size: 20px;
          }
        } 

        @media (max-width: 1420px) {
          .nav-dropdown-container {
            font-size: 18px;
          }
        }
          @media (max-width: 1350px) {
          .dropdown-title {
            font-size: 1.8vw; 
          }
          
          .dropdown-subtitle {
            font-size: 1vw;
          }
        }

        @media (max-width: 1300px) {
          .logo-text {
            font-size: 1.5vw;
          }
          .nav-dropdown-container {
            font-size: 17px;
                        padding: 0 6px;
          }
          .price-text {
            font-size: 1.5vw;
        }
                      .pinx-text {
            font-size: 1.5vw;
        }
        }

        @media (max-width: 1200px) {
                  .nav-dropdown-container {
            font-size: 16px;
          }
          .nav-separator {
            display: block; 
          }
        }
        @media (max-width: 1150px) {
          .logo-text {
            font-size: 1.5vw;
            white-space: nowrap;
          }
        }
                  @media (max-width: 1050px) {
                            .nav-dropdown-container {
            font-size: 15px;
                        padding: 0 6px;
          }
          .price-text {
            font-size: 1.3vw;
        }
                      .pinx-text {
            font-size: 1.3vw;
        }    
        }
      `}</style>
    </div>
  );
};

export default LaptopNavbar;

