import React from 'react';
import Link from 'next/link';

interface MobileMenuProps {
  toggleModal: () => void;
}

const MobileNavbar: React.FC<MobileMenuProps> = ({ toggleModal }) => {
  return (
    <>
      <div className="nav-holder">
        <div className="logo-nav-container">
          <div className="logo-text">
            <Link href="/" passHref>
              <a className="logo-text">PINX Global</a>
            </Link>
          </div>
        </div>

        <div className="hamburger" onClick={toggleModal}>
                  &#9776;
                </div>
                <style jsx global>{`

                        a, a:active, a:focus, a:hover {
          outline: none;
          text-decoration: none;
          color: white;
          transition: transform 0.3s ease;
        }
        a:hover {
          transform: scale(1.1);
        }

        .nav-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 12px;
        background: linear-gradient(to bottom, rgba(80, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        color: #fff;
        position: relative;
        z-index: 1000;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); 
        }
        .logo-nav-container {
          display: flex;
          align-items: center;
        }
        .logo-text {
          font-weight: bold;
          font-size: 25px;
          color: #fff;
          margin: 0;
        }

                  .hamburger {
            font-size: 3vw;
            cursor: pointer;
          }

        @media (max-width: 1000px) {
          .logo-text {
            font-size: 3vw;
            white-space: nowrap;
          }
          .nav-links {
            display: none;
          }
          .hamburger {
            display: block;
          }
          .modal a {
            font-size: 4vw;
          }
          .separator-small, .nav-separator {
            display: none;
          }
          .hamburger-container {
            display: flex;
            align-items: center;
            gap: 1vw;
          }
        }
        @media (max-width: 800px) {
          .logo-text {
            font-size: 3.5vw;
            white-space: nowrap;
          }
          .nav-links {
            display: none;
          }
          .hamburger {
            display: block;
            font-size: 4vw;
          }
          .modal a {
            font-size: 4.5vw;
          }
          .separator-small, .nav-separator {
            display: none;
          }
          .hamburger-container {
            display: flex;
            align-items: center;
            gap: 1vw;
          }
        }
        @media (max-width: 600px) {
          .nav-holder {
            flex: 1;
            padding: 0 20px;
          }
          .logo-text {
            font-size: 4.5vw;
            white-space: nowrap;
          }
          .modal a {
            font-size: 6vw;
          }
          .nav-separator {
            margin: 0 1.5vw;
          }
          .nav-dropdown-container {
            padding: 0;
          }
          .hamburger {
            font-size: 5vw;
          }
        }
        @media (max-width: 450px) {
          .logo-text {
            font-size: 6.5vw;
            white-space: nowrap;
          }
                      .hamburger {
            font-size: 6vw;
          }
        }
        @media (max-width: 450px) {
          .logo-text {
            font-size: 7.5vw;
            white-space: nowrap;
          }
        }
      `}</style>
    
    
      </div>
    </>
  );
};

export default MobileNavbar;
