import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const NewsDropdown = () => {
  const [isNewsHovered, setIsNewsHovered] = useState(false);

  return (
    <div
      className={`nav-dropdown-container ${isNewsHovered ? 'modalSelected' : ''}`}
      onMouseEnter={() => setIsNewsHovered(true)}
      onMouseLeave={() => setIsNewsHovered(false)}
    >
      News
      <svg
        className={`nav-dropdown-arrow ${isNewsHovered ? 'rotate' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>
      {isNewsHovered && (
        <div className="nav-dropdown-content">
          <Link href="/news" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/news.png"
                    alt="News Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                <div className="dropdown-title">Latest News</div>
                  <div className="dropdown-subtitle">
                  Stay updated with the latest news and updates in the market.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>

          <Link href="/news/calendar" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image
                    src="/assets/deadline.png"
                    alt="Calendar Icon"
                    width={40}
                    height={40}
                  />
                </div>
                <div className="dropdown-content">
                <div className="dropdown-title">Market Calendar</div>
                  <div className="dropdown-subtitle">
                  View upcoming market events and key dates.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default NewsDropdown;
