import React from 'react';
import WalletModalHeader from './WalletModalHeader';  
import MenuLinks from './MenuLinks';
import PinxPriceRow from './PinxPriceRow';  

interface MobileMenuProps {
  toggleModal: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>; 
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isModalOpen, toggleModal, setIsModalOpen }) => {
  return (
    <>
      <div className={`modal ${isModalOpen ? 'open' : 'closed'}`}>
        <WalletModalHeader toggleModal={toggleModal} setIsModalOpen={setIsModalOpen}/>

        <PinxPriceRow />

        <div className="menu-links-container">
          <MenuLinks toggleModal={toggleModal} />
        </div>
      </div>

      <style jsx>{`
        .modal {
          display: flex;
          flex-direction: column;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.95);
          z-index: 2000;
          transition: transform 0.3s ease-in-out;
          transform: translateX(100%); /* Start off-screen to the right */
        }

        .open {
          transform: translateX(0); /* Slide in when open */
        }

        .closed {
          transform: translateX(100%); /* Slide out when closed */
        }

        /* Menu links container that takes the remaining space */
        .menu-links-container {
          margin-top: 12px;
          flex: 1;
          width: 100%;
          overflow-y: auto;
          margin-bottom: 15px;
        }

        .menu-links-container::-webkit-scrollbar {
          display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
        }

        .menu-links-container {
          -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
          scrollbar-width: none; /* Hide scrollbar for Firefox */
        }
      `}</style>
    </>
  );
};

export default MobileMenu;
